
import Link from '@/types/Link';
import { defineComponent, computed, PropType } from 'vue';
import { useStore } from 'vuex';
export default defineComponent({
  name: "Aside",
  props: {
    links: {
      required: true,
      type: Array as PropType<Link[]>
    },
  },
  setup() {
    const store = useStore();
    const slide = computed(() => store.getters.slide);
    const width = computed(() => store.getters.width);

    return { slide, width };
  },
})
