
 // @ is an alias to /src
import { defineComponent, computed, onBeforeUnmount, onMounted } from 'vue';
import { useStore } from 'vuex';
import Header from '@/components/partials/Header.vue';
export default defineComponent({
  components: {
    Header,
  },
  setup() {
    const store = useStore();
    const setSlide = (slide: boolean) => store.dispatch('setSlide', slide);
    const setNav = (nav: boolean) => store.dispatch('setNav', nav);
    const slide = computed(() => store.getters.slide);
    const width = computed(() => store.getters.width);
    const nav = computed(() => store.getters.nav);
    const handleSlide = async () => {
      await setSlide(!slide.value);
      await setNav(!nav.value);
    };
    const handleScroll = (event: Event, el: HTMLElement) => {
      if (window && window.pageYOffset > 50) {
        el.classList.add("active");
      } else {
        el.classList.remove("active");
      }
    };
    const handleToTop = () => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    };
    onMounted(() => {
      window.addEventListener("scroll", () => {
        handleScroll
      });
    });
    onBeforeUnmount(() => {
      window.removeEventListener("scroll", () => {
        handleScroll
      });
    });

    return { slide, width, nav, handleSlide, handleScroll , handleToTop};
  },
  directives: {
    scroll: {
      // directive definition
      mounted(el: HTMLElement, binding) {
        let fn = function(evt: Event) {
          if (binding.value(evt, el)) {
            window.removeEventListener('scroll', fn);
          }
        };
        window.addEventListener('scroll', fn);
      }
    }
  }
});
