
import { defineComponent, computed } from 'vue';
import { useStore } from 'vuex';
export default defineComponent({
  name: "Navigation",
  props: ['name'],
  setup() {
    const store = useStore();
    const nav = computed(() => store.getters.nav);
    const width = computed(() => store.getters.width);

    return { nav, width };
  },
})
