
 // @ is an alias to /src
import Click from '@/types/Click';
import { defineComponent, PropType } from 'vue';
export default defineComponent({
  name: "QuickCard",
  props: {
    clicks: {
      required: true,
      type: Array as PropType<Click[]>
    },
  },
});
