
 // @ is an alias to /src
import { defineComponent, computed, ref, watch, onMounted, nextTick } from 'vue';
import { useStore } from 'vuex';
export default defineComponent({
  name: "Header",
  setup() {
    const windowWidth = ref(0);
    const store = useStore();

    const setSlide = (slide: boolean) => store.dispatch('setSlide', slide);
    const setWidth = (width: boolean) => store.dispatch('setWidth', width);
    const setNav = (nav: boolean) => store.dispatch('setNav', nav);

    const slide = computed(() => store.getters.slide);
    const width = computed(() => store.getters.width);
    const nav = computed(() => store.getters.nav);

    watch(windowWidth, () => {
      if (windowWidth.value >= 981) {
        setSlide(false);
        setWidth(true);
        setNav(true);
      } else if (windowWidth.value >= 700) {
        setSlide(true);
        setWidth(true);
        setNav(true);
      } else {
        setSlide(true);
        setWidth(false);
        setNav(false);
      }
    });

    onMounted(() => {
      nextTick(() => {
        window.addEventListener("resize", handleWindowWidth);
        //Init
        handleWindowWidth();
      })
    });

    const handleWindowWidth = async () => {
      windowWidth.value = window.innerWidth;
    };

    const handleSlide = async () => {
      await setSlide(!slide.value);
      await setNav(!nav.value);
    };

    return { slide, width, nav, windowWidth, handleWindowWidth, handleSlide };
  },
});
