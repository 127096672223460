import { createStore } from 'vuex'

export default createStore({
  state: {
    slide: false,
    width: false,
    nav: false,
  },
  getters: {
		slide: state => state.slide,
		width: state => state.width,
		nav: state => state.nav,
  },
  mutations: {
		SET_SLIDE(state, payload) {
			state.slide = payload;
		},
		SET_WIDTH(state, payload) {
			state.width = payload;
		},
		SET_NAV(state, payload) {
			state.nav = payload;
		},
  },
  actions: {
		async setSlide(context, payload) {
			context.commit('SET_SLIDE', payload);
		},
    async setWidth(context, payload) {
      context.commit('SET_WIDTH', payload);
    },
    async setNav(context, payload) {
      context.commit('SET_NAV', payload);
    }
  },
  modules: {
  }
})
