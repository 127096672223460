
 // @ is an alias to /src
import Navigation from '@/components/partials/Navigation.vue';
import Aside from '@/components/partials/Aside.vue';
import QuickCard from '@/components/partials/QuickCard.vue';
import Footer from '@/components/partials/Footer.vue';
import { useStore } from 'vuex';
import { computed, defineComponent, reactive, ref } from 'vue';
export default defineComponent({
  name: "Home",
  components: {
    Navigation,
    Aside,
    QuickCard,
    Footer,
  },
  setup() {
    const store = useStore();
    const clicks = reactive([
      {
        name: "Account",
        route: "Account"
      },
      {
        name: "User",
        route: "User"
      },
      {
        name: "Exam",
        route: "Exam"
      },
      {
        name: "Lesson",
        route: "Lesson"
      },
    ]);
    const links = reactive([
      {
        name: "Overview",
        url: "account"
      },
      {
        name: "Quick Links",
        url: "account"
      },
    ]);
    const name = ref("Home");
    const nav = computed(() => store.getters.nav);

    return { name, nav, links, clicks };
  },
});
