import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import('../views/Account.vue'),
  },
  {
    path: '/account/registration',
    name: 'Registration',
    component: () => import('@/views/account/Registration.vue'),
    meta: { guest: true },
  },
  {
    path: '/account/subscription',
    name: 'Subscription',
    component: () => import('@/views/account/Subscription.vue'),
    meta: { guest: true },
  },
  {
    path: '/account/verification',
    name: 'Verification',
    component: () => import('@/views/account/Verification.vue'),
    meta: { guest: true },
  },
  {
    path: '/user',
    name: 'User',
    component: () => import('../views/User.vue'),
  },
  {
    path: '/user/create',
    name: 'CreateUser',
    component: () => import('@/views/user/Create.vue'),
    meta: { guest: true },
  },
  {
    path: '/user/edit',
    name: 'EditUser',
    component: () => import('@/views/user/Edit.vue'),
    meta: { guest: true },
  },
  {
    path: '/user/delete',
    name: 'DeleteUser',
    component: () => import('@/views/user/Delete.vue'),
    meta: { guest: true },
  },
  {
    path: '/user/role',
    name: 'Role',
    component: () => import('@/views/user/Role.vue'),
    meta: { guest: true },
  },
  {
    path: '/exam',
    name: 'Exam',
    component: () => import('../views/Exam.vue'),
  },
  {
    path: '/exam/create',
    name: 'CreateExam',
    component: () => import('@/views/exam/Create.vue'),
    meta: { guest: true },
  },
  {
    path: '/exam/edit',
    name: 'EditExam',
    component: () => import('@/views/exam/Edit.vue'),
    meta: { guest: true },
  },
  {
    path: '/exam/score',
    name: 'Score',
    component: () => import('@/views/exam/Score.vue'),
    meta: { guest: true },
  },
  {
    path: '/lesson',
    name: 'Lesson',
    component: () => import('../views/Lesson.vue'),
  },
  {
    path: '/lesson/create',
    name: 'CreateLesson',
    component: () => import('@/views/lesson/Create.vue'),
    meta: { guest: true },
  },
  {
    path: '/lesson/edit',
    name: 'EditLesson',
    component: () => import('@/views/lesson/Edit.vue'),
    meta: { guest: true },
  },
	{
		path: '/404',
		name: 'NotFound',
		component: () => import('../views/NotFound.vue'),
		meta: { guest: true },
	},
	{
		path: "/:catchAll(.*)", // Unrecognized path automatically matches 404
		redirect: { name: 'NotFound' }
	}
]

const router = createRouter({
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || new Promise((resolve) => {
      setTimeout(() => resolve({ top: 0 }), 300);
    })
  },
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
